<template>
  <LoginView>
    <Form
      :is-loading="isLoading"
      @click:change="onClickChange"
      @click:back="$router.push({ name: 'login' })"
    />
  </LoginView>
</template>

<script>
import { ref, inject, computed } from "vue";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";
import { useStore } from "@/store";
// Components
import Form from "@/views/general/changePassword/Form";
import LoginView from "@/views/general/LoginView";

export default {
  components: {
    Form,
    LoginView
  },
  setup() {
    // Misc
    const { t } = useI18n();
    const router = useRouter();
    const store = useStore();
    const toast = inject("toast");

    // Data
    const isLoading = ref(false);

    // Computed
    const documentTitle = computed(() => t("app.change_password"));

    // Methods
    const removeTemporaryPassword = () => {
      store.dispatch("user/setTemporaryPassword", null);
    };

    const redirect = () => {
      router.push({ name: "login" });
    };

    const onClickChange = async data => {
      isLoading.value = true;

      const { payload } = await store.dispatch("api/request", {
        endpoint: "password.change",
        toast,
        data
      });

      if (payload.message.status === "Success") {
        removeTemporaryPassword();
        redirect();
      }

      isLoading.value = false;
    };

    const onClickBack = () => {
      removeTemporaryPassword();
      redirect();
    };

    return {
      isLoading,
      documentTitle,
      onClickChange,
      onClickBack
    };
  }
};
</script>
