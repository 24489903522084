<template>
  <div>
    <slot :id="id" name="label">
      <label v-if="label" :for="id" class="form-label">
        {{ label }}
      </label>
    </slot>

    <input
      :id="id"
      :value="modelValue"
      :type="type"
      :class="inputClass"
      :disabled="disabled"
      :readonly="readonly"
      autocomplete="off"
      :placeholder="placeholder"
      @input="$emit('update:modelValue', $event.target.value)"
    />

    <PasswordMeter class="password-meter" :password="modelValue" />
  </div>
</template>

<script>
import InputMixin from "@/mixins/InputMixin";
import PasswordMeter from "vue-simple-password-meter";

export default {
  components: {
    PasswordMeter
  },
  mixins: [InputMixin],
  props: {
    type: {
      type: String,
      default: "password",
      validator: value => ["text", "password"].includes(value)
    }
  }
};
</script>

<style scoped lang="scss">
.password-meter.po-password-strength-bar {
  @apply mt-2;
}
</style>

<style>
.po-password-strength-bar {
  background-color: lightgray;
}
</style>
