<template>
  <div class="h-screen xl:h-auto flex py-5 xl:py-0 my-10 xl:my-0">
    <div
      class="my-auto mx-auto xl:ml-20 bg-white dark:bg-dark-1 xl:bg-transparent px-5 sm:px-8 py-8 xl:p-0 rounded-md shadow-md xl:shadow-none w-full sm:w-3/4 lg:w-2/4 xl:w-auto"
    >
      <h2
        class="intro-x font-bold text-2xl xl:text-3xl text-center xl:text-left"
      >
        {{ $t("app.change_password") }}
      </h2>

      <div class="intro-x mt-8">
        <input
          v-model="value.username"
          type="text"
          class="intro-x login__input form-control py-3 px-4 border-gray-300 block"
          :placeholder="$t('app.username')"
          @keyup.enter="onClickChange"
        />

        <VPassword
          v-if="!hasTemporaryPassword"
          v-model="v.old_password.$model"
          input-class="intro-x login__input form-control py-3 px-4 border-gray-300 block mt-4"
          :placeholder="$t('app.old_password')"
          @keyup.enter="onClickChange"
        />

        <VPassword
          v-model="v.new_password.$model"
          input-class="intro-x login__input form-control py-3 px-4 border-gray-300 block mt-4"
          :placeholder="$t('app.new_password')"
          @keyup.enter="onClickChange"
        />

        <VText
          v-model="v.confirm_password.$model"
          type="password"
          input-class="intro-x login__input py-3 px-4 block"
          class="mt-3"
          :placeholder="$t('app.confirm_password')"
          :errors="v.confirm_password.$errors"
          @keyup.enter="onClickChange"
        />
      </div>

      <div class="intro-x mt-5 xl:mt-8 text-center xl:text-left">
        <VButton
          :label="$t('app.change')"
          class="btn-primary py-3 px-4 w-full xl:w-32 xl:mr-3 align-top"
          :is-loading="isLoading"
          @click="onClickChange"
        />

        <VButton
          :label="$t('app.back')"
          class="btn-outline-primary py-3 px-4 w-full xl:w-32 xl:mr-3 align-top"
          @click="$emit('click:back')"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { ref, computed } from "vue";
import { useRoute } from "vue-router";
import useVuelidate from "@vuelidate/core";
import { sameAs } from "@/i18n/i18n-validators";
import { useStore } from "@/store";
// Components
import VButton from "@/components/VButton";
import VText from "@/components/inputs/VText";
import VPassword from "@/components/inputs/VPassword";

export default {
  components: {
    VButton,
    VText,
    VPassword
  },
  props: {
    isLoading: {
      type: Boolean,
      default: false
    }
  },
  emits: ["click:change", "click:back"],
  setup(_, { emit }) {
    const store = useStore();
    const route = useRoute();

    // COMPUTED
    const password = computed(() => value.value.new_password);

    // DATA
    const value = ref({
      username: route.params?.username ?? "",
      old_password: store.getters["user/temporary_password"],
      confirm_password: "",
      new_password: ""
    });

    const hasTemporaryPassword =
      store.getters["user/temporary_password"] ?? false;

    const rules = {
      username: {},
      old_password: {},
      confirm_password: { sameAs: sameAs(password) },
      new_password: {}
    };

    const v = useVuelidate(rules, value.value);

    // METHODS
    const onClickChange = async () => {
      const isValid = await v.value.$validate();

      if (!isValid) {
        return;
      }

      emit("click:change", value.value);
    };

    return {
      value,
      v,
      onClickChange,
      password,
      hasTemporaryPassword
    };
  }
};
</script>
