<template>
  <div>
    <div class="container sm:px-10">
      <div class="block xl:grid grid-cols-2 gap-4">
        <!-- BEGIN: Login Info -->
        <div class="hidden xl:flex flex-col min-h-screen">
          <a href="" class="-intro-x flex items-center pt-5">
            <img
              alt="Chexpertise"
              class="w-1/4"
              :src="require(`@/assets/images/chexpertise.svg`)"
            />
          </a>

          <div class="my-auto">
            <img
              alt="Chexpertise"
              class="-intro-x w-1/2 -mt-16"
              :src="require(`@/assets/images/illustration.svg`)"
            />

            <div
              class="-intro-x text-white font-medium text-4xl leading-tight mt-10"
            >
              {{ $t("app.login_title_1") }} <br />

              <span class="lowercase">
                {{ $t("app.login_title_2") }}
              </span>
            </div>
            <div
              class="-intro-x mt-5 text-lg text-white text-opacity-70 dark:text-gray-500"
            >
              {{ $t("app.login_subtitle") }}
            </div>
          </div>
        </div>
        <!-- END: Login Info -->
        <slot />
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, onMounted } from "vue";

export default defineComponent({
  setup() {
    onMounted(() => {
      cash("body")
        .removeClass("main")
        .removeClass("error-page")
        .addClass("login");
    });
  }
});
</script>
